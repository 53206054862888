import React from 'react';

const Bio = () => {
  const fieldwireLink = (
    <a
      id='bioFieldwireLink'
      href='https://www.fieldwire.com/'
      rel='noopener noreferrer'
      target='_blank'
    >
      Fieldwire
    </a>
  )
  return (
    <div id='bioContainer'>
      <p id='bioText'>
        <span style={{fontSize: '42px'}}>Hello.</span>
        <br />
        <br />
        My name is Michael Sandor and I am a frontend engineer
        at {fieldwireLink}
      </p>
    </div>
  );
};

export default Bio;
