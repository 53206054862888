import React from 'react';
import ReactGA from 'react-ga';
import '../styles/home.css';

import Bio from '../components/Home/Bio';
import Navbar from '../components/Home/Navbar';

export class Home extends React.Component<{}, {}> {
  componentDidMount() {
    ReactGA.initialize('UA-252386994');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className='baseContainer'>
        <Navbar />        
        <div id='homeContainer'>
          <Bio />
        </div>
      </div>
    );
  };
}
