import React from 'react';

const Navbar = () => {
  return (
    <nav id='navbar'>
      <div>
        <a
          className='navbarLink'
          href='https://github.com/MichaelSandor'
          rel='noopener noreferrer'
          target='_blank'
        >
          GitHub
        </a>
        &nbsp; | &nbsp;
        <a
          className='navbarLink'
          href='https://www.linkedin.com/in/sandormichael/'
          rel='noopener noreferrer'
          target='_blank'
        >
          LinkedIn
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
